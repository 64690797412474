import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><a parentName="p" {...{
        "href": "https://www.w3.org/WAI/tutorials/menus/"
      }}>{`Navigationsmenüs`}</a>{` sind ein wesentlicher Bestandteil jeder Website. Sie
sind meistens in der Kopfzeile platziert und bieten einen Überblick über die Seitenhierarchie einer Website.
Nutzer:innen können sich einen Überblick über den Inhalt verschaffen und schnell zu den wichtigsten
Abschnitten der Website navigieren.`}</p>
    <p>{`Viele Websites verwenden Fly-Out-Menüs (oder Drop-Down-Menüs), bei denen bestimmte Menüpunkte ein Untermenü öffnen,
wenn man sie anklickt oder den Mauszeiger über sie bewegt. Oft sind diese Menüs für Screenreader- und Tastatur-Nutzer:innen
nur sehr schlecht zugänglich. Aber wir können es besser machen!`}</p>
    <p>{`Ich zeige euch, wie ihr auf einfache Weise ein barrierefreies Fly-Out-Menü bauen könnt. Dazu nutzen wir die Fähigkeiten
moderner Web-Features wie der `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Popover_API"
      }}>{`Popover API`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAFqzCYyCf/EABoQAAIDAQEAAAAAAAAAAAAAAAECAAMTBBH/2gAIAQEAAQUCRUaVtnD1GU1+IW0hAB//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAWEQEBAQAAAAAAAAAAAAAAAAAAASH/2gAIAQIBAT8BjX//xAAcEAABBAMBAAAAAAAAAAAAAAAAAQISIRExQXH/2gAIAQEABj8C74LJxTSebLRDR//EABsQAQADAAMBAAAAAAAAAAAAAAEAESExQVFh/9oACAEBAAE/IaGkOwYvIcXLXX9g7+VPIAM3kq0f/9oADAMBAAIAAwAAABAAz//EABYRAQEBAAAAAAAAAAAAAAAAAAEQIf/aAAgBAwEBPxBwn//EABcRAAMBAAAAAAAAAAAAAAAAAAABEVH/2gAIAQIBAT8Qpsmj/8QAHxABAAIBAwUAAAAAAAAAAAAAAQARQSExkVFhgaHh/9oACAEBAAE/EBw9EGqU7vE0aJpuDAHM2lepW+ohKm42BivEFrUsGGLLkxr8n//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Eine Hand hält einen Kompass zur Navigation hoch. Im Hintergrund ist eine felsige Küste mit Bäumen zu sehen.",
          "title": "Eine Hand hält einen Kompass zur Navigation hoch. Im Hintergrund ist eine felsige Küste mit Bäumen zu sehen.",
          "src": "/static/e9cfc3e8c39caf2282ab761716c4782d/e5166/pexels-valentinantonucci-compass.jpg",
          "srcSet": ["/static/e9cfc3e8c39caf2282ab761716c4782d/f93b5/pexels-valentinantonucci-compass.jpg 300w", "/static/e9cfc3e8c39caf2282ab761716c4782d/b4294/pexels-valentinantonucci-compass.jpg 600w", "/static/e9cfc3e8c39caf2282ab761716c4782d/e5166/pexels-valentinantonucci-compass.jpg 1200w", "/static/e9cfc3e8c39caf2282ab761716c4782d/b17f8/pexels-valentinantonucci-compass.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Valentin Antonucci / pexels.com`}</em></p>
    <h2>{`Was wir erreichen wollen`}</h2>
    <p>{`Unser Navigationsmenü sollte für verschiedene Nutzergruppen einfach zu bedienen sein:`}</p>
    <ul>
      <li parentName="ul">{`Die Untermenüs sollten `}<strong>{`über dem Inhalt der Website`}</strong>{` erscheinen und nicht sofort verschwinden,
wenn die Maus den anklickbaren Bereich verlässt.`}</li>
      <li parentName="ul">{`Nutzer:innen von Bildschirmlesegeräten benötigen `}<strong>{`aussagekräftiges Markup`}</strong>{`, um das Navigationsmenü
zu verstehen und zu bedienen.`}</li>
      <li parentName="ul">{`Tastaturnutzer:innen sollen das Menü `}<strong>{`allein mit der Tastatur`}</strong>{` bedienen können.`}</li>
    </ul>
    <h2>{`Demo: Barrierefreies Navigationsmenü`}</h2>
    <p>{`Ich habe eine `}<a parentName="p" {...{
        "href": "https://codepen.io/alexlehner86/full/ExqWMpb"
      }}>{`CodePen-Demo`}</a>{` eines barrierefreien Navigationsmenüs mit
der Popover-API erstellt. Öffnet die Demo auf einem Desktop-Bildschirm oder einem Tablet im Querformat, um das Fly-Out-Menü
zu sehen. Auf kleineren Geräten wird die Demo stattdessen ein mobiles Menü anzeigen.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.666666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABJ0AAASdAHeZh94AAABf0lEQVQoz51SCW6DMBD0/39XpeWIweGMCcZcpoQrTGW30KSNVKkrjVgx3tnZtYlIJejLCYnHQQ8B2uYdWV7gIkoMw4i6rjGNI9q2RVFIjF952yrcx7qu5ktWrIhziTgvIFWHs5DgRbUfuN1W9MOEebk9CEzLgn6ccB0nTPOyixLd0XaPBkEQYFmWL6FPASlLHN4sHKln+DAM0SmF7JLDclzDxel5P0+maQI7BaA+Q5Sk+BllWcE5UiRJbMZXShknhZRwqYcwjDAOw/fI8zzD9Xx47ITkzNH3vSnSGIYBdd0YFzzLHhqlnONg2cZMHMcG1+sAsswzWBgZHH2GsqrQdd0uWFU1LNsB59nDKoTIcWIMaZKgaRpTY3aoScYYXt8sOI5rhO6jqmvYLjUO9Hr+CqL3wvkZIs9NF31J2pmGzt+7DqWUkFL+4p+BaHeUUgghTIFuoEfYoN+cUp3hdH7PPQPxfR+2bSPLLnvRdikbPh/y7//PQKIoghbdHG7F/8UHqjBHeOrk0+gAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Screenshot der Demo-Seite des barrierefreien Navigationsmenüs. Das Untermenü des Menüpunkts Solutions ist geöffnet.",
          "title": "Screenshot der Demo-Seite des barrierefreien Navigationsmenüs. Das Untermenü des Menüpunkts Solutions ist geöffnet.",
          "src": "/static/84ff8009114bb2d0138210e82003075e/c1b63/screenshot_accesible_nav_menu.png",
          "srcSet": ["/static/84ff8009114bb2d0138210e82003075e/5a46d/screenshot_accesible_nav_menu.png 300w", "/static/84ff8009114bb2d0138210e82003075e/0a47e/screenshot_accesible_nav_menu.png 600w", "/static/84ff8009114bb2d0138210e82003075e/c1b63/screenshot_accesible_nav_menu.png 1200w", "/static/84ff8009114bb2d0138210e82003075e/bb3b7/screenshot_accesible_nav_menu.png 1211w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Alexander Lehner`}</em></p>
    <p>{`Das Navigationsmenü ist für Maus-, Tastatur- und Screenreader-Nutzer:innen leicht zu bedienen. Schauen wir uns die
wichtigsten Elemente an.`}</p>
    <h3>{`Aussagekräftiges Markup`}</h3>
    <p>{`Wir verwenden das `}<InlineCode mdxType="InlineCode">{`nav`}</InlineCode>{`-Element, um die Rolle des Menüs zu vermitteln. Die Menüpunkte
befinden sich in einer ungeordneten Liste (`}<InlineCode mdxType="InlineCode">{`ul`}</InlineCode>{`-Element). So können assistive Technologien die
Anzahl der Menüpunkte vorlesen und entsprechende Navigationsfunktionen bereitstellen.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<nav class="main-nav">
    <ul>
        <li>
            <button popovertarget="nav-panel-solutions" type="button">Solutions</button>
            <div id="nav-panel-solutions" popover>...</div>
        </li>
        <li><a href="#">Products</a></li>
        <li>...</li>
        <li>...</li>
    </ul>
</nav>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Die Listenelemente enthalten entweder einen direkten Link zu einer Unterseite, oder sie enthalten
ein `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{`-Element, das ein Untermenü mit `}<InlineCode mdxType="InlineCode">{`popover`}</InlineCode>{`-Attribut öffnet.
Die Schaltfläche ist mit dem Popover-Inhalt über das Attribut `}<InlineCode mdxType="InlineCode">{`popovertarget`}</InlineCode>{` verknüpft.`}</p>
    <p>{`Einer der großen Vorteile der Popover-API: Sie teilt den assistiven Technologien automatisch mit, ob das Untermenü
gerade ein- oder ausgeklappt ist. Weitere Informationen findet ihr im Artikel
`}<a parentName="p" {...{
        "href": "https://hidde.blog/popover-accessibility/"
      }}>{`„On popover accessibility: what the browser does and doesn’t do“`}</a>{`.`}</p>
    <h3>{`Vermeidet die `}<InlineCode mdxType="InlineCode">{`menu`}</InlineCode>{`-Rolle`}</h3>
    <p>{`In meiner Demo verwende ich die Rollen `}<InlineCode mdxType="InlineCode">{`menu`}</InlineCode>{` und `}<InlineCode mdxType="InlineCode">{`menuitem`}</InlineCode>{` nicht
für das Navigationsmenü. Das ist Absicht! Diese ARIA-Rollen sind nur für Menü-Widgets gedacht, die sich wie native
Betriebssystem-Menüs verhalten.`}</p>
    <p>{`Lest den Artikel `}<a parentName="p" {...{
        "href": "https://adrianroselli.com/2017/10/dont-use-aria-menu-roles-for-site-nav.html"
      }}>{`„Don’t Use ARIA Menu Roles for Site Nav“`}</a>{`
von Adrian Roselli für eine detaillierte Erklärung.`}</p>
    <h3>{`Visuelle Platzierung`}</h3>
    <p>{`Wenn ein Untermenü geöffnet wird, erscheint es automatisch im `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Glossary/Top_layer"
      }}>{`Top-Layer`}</a>{`,
einer separaten Ebene, die über allen anderen im Webdokument angezeigten Ebenen liegt. Ihr müsst euch nur um die
Platzierung und Größe des Untermenüs kümmern:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`header nav.main-nav [popover] {
    top: var(--header-height);
    margin: 0;
    width: 100%;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Popover-Inhalte erscheinen standardmäßig in der Mitte des Bildschirms. Um unsere Untermenüs direkt unterhalb
der Kopfzeile zu platzieren, setzen wir für `}<InlineCode mdxType="InlineCode">{`top`}</InlineCode>{` die Höhe der Kopfzeile und entfernen
jeglichen `}<InlineCode mdxType="InlineCode">{`margin`}</InlineCode>{`. Anschließend setzen wir `}<InlineCode mdxType="InlineCode">{`width`}</InlineCode>{` auf `}<InlineCode mdxType="InlineCode">{`100%`}</InlineCode>{`,
damit das Untermenü die gesamte Breite der Seite einnimmt.`}</p>
    <h3>{`Tastaturbedienbarkeit`}</h3>
    <p>{`Mit der `}<InlineCode mdxType="InlineCode">{`TAB`}</InlineCode>{`-Taste könnt ihr die Links und Schaltflächen im Navigationsmenü fokussieren. Nach dem
Öffnen eines Untermenüs ist dessen Inhalt der nächste in der Fokusreihenfolge. Durch Drücken der `}<InlineCode mdxType="InlineCode">{`ESC`}</InlineCode>{`-Taste
wird das Untermenü geschlossen und der Fokus automatisch wieder auf die Trigger-Schaltfläche gesetzt. Alles dank der mächtigen
Popover-API! 🤩`}</p>
    <h3>{`Light-Dismiss der Untermenüs`}</h3>
    <p>{`Ein weiteres integriertes Feature der Popover-API ist Light-Dismiss: Wenn ihr einen Bereich außerhalb des Untermenüs
anklickt, wird das Untermenü geschlossen und der Fokus auf das Trigger-Element zurückgesetzt. Ihr müsst euch nicht mehr
mit `}<InlineCode mdxType="InlineCode">{`focusout`}</InlineCode>{`-Events in JavaScript herumschlagen.`}</p>
    <h2>{`Nützliche Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/tutorials/menus/"
        }}>{`Navigation Menus Tutorial (W3C)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/ARIA/apg/patterns/disclosure/"
        }}>{`Disclosure Pattern (ARIA APG)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/ARIA/apg/patterns/disclosure/examples/disclosure-navigation/"
        }}>{`Example Disclosure Navigation Menu (ARIA APG)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/API/Popover_API"
        }}>{`Popover API (MDN)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      